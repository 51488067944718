import { SET_COMPANY } from './-mutation-types';
import { useCompaniesStore } from '~/stores/companies';
import CompanyService from '~/services/api/CompanyService';
import type { Company } from '~/models/Company';

const Actions = {
    async fetchCompany(companyId: number) {
        const companiesStore = useCompaniesStore();
        const companyService = new CompanyService();
        const response = await companyService.fetchCompany(companyId);

        companiesStore[SET_COMPANY](response);
    },
    setCompany(company: Company) {
        const companiesStore = useCompaniesStore();
        companiesStore[SET_COMPANY](company);
    },
};

export default Actions;
