/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './companies/state';
import Getters from './companies/getters';
import Actions from './companies/actions';
import Mutations from './companies/mutations';

export const useCompaniesStore = defineStore(
    'companies',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
