import { GET_COMPANY } from './-getter-types';
import { useCompaniesStore } from '~/stores/companies';
import type { Company } from '~/models/Company';

const Getters = {
    [GET_COMPANY]: (): Company | null => {
        const companiesStore = useCompaniesStore();

        return companiesStore.company;
    },
};

export default Getters;
