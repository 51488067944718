import { SET_COMPANY } from './-mutation-types';
import { useCompaniesStore } from '~/stores/companies';
import type { Company } from '~/models/Company';

const Mutations = {
    [SET_COMPANY](payload: Company | null) {
        const companiesStore = useCompaniesStore();
        companiesStore.company = payload;
    },
};

export default Mutations;
