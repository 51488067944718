import type { Company } from '~/models/Company';

export type CompanyState = {
    company: Company | null;
}

export const defaultStateData = (): CompanyState => ({
    company: null,
});

export const DefaultState = (): CompanyState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
